import * as api from '../api'
import * as types from '../constants/action-types'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


/********** Orders ********/
//All Orders

export const getAllOrders = (orders) => ({
    type: types.RECEIVE_ORDERS,
    orders
})

export const setOrderDetails = (order) => ({
    type: types.SET_ORDER_DETAILS,
    order
})

export const resetOrders = () => ({
    type: types.RESET_ORDERS,
})

export const setOrderId = (orderid) => ({
    type: types.SET_ORDERID,
    orderid: orderid
})

/********** User Action ********/
//Addresses
export const getAllAddresses = (addresses) => (dispatch) => {
    dispatch(addAddress(addresses));
}

export const addAddress = (addresses) => ({
    type: types.GET_ADDRESSES,
    addresses
});

export const setDefaultAddress = (address) => (dispatch) => {
    dispatch(defaultAddress(address));
}

export const setSelectedAddress = (address) => ({
    type: types.SET_SELECTED_ADDRESS,
    address
})

export const defaultAddress = (address) => ({
    type: types.SET_DEFAULT_ADDRESS,
    address
});

export const resetAddresses = () => ({
    type: types.RESET_ADDRESSES
});

// change PAYMENT method
export const changePayment = (paymentType) => ({
    type: types.CHANGE_PAYMENT_TYPE,
    paymentType
})

/********** User Action ********/
//Login
export const userLogin = (user) => (dispatch) => {
    // toast.success("LoggedIn Successfully");
    dispatch(loginUser(user));
}

export const loginUser = (user) => ({
    type: types.LOGIN,
    user
});

// search customer saved
export const saveCustomer = (customer) => ({
    type: types.CUSTOMER,
    customer
})
//Logout
export const userLogout = (user) => (dispatch) => {
    dispatch(logoutUser(user));
}

export const logoutUser = (user) => ({
    type: types.LOGOUT,
    user
});


/********** Product Action ********/
// recieve products
export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
});

export const receiveCategories = categories => ({
    type: types.RECEIVE_CATEGORIES,
    categories
});

export const receiveSubCategories = categories => ({
    type: types.RECEIVE_SUBCATEGORIES,
    categories
});

export const receiveTeams = teams => ({
    type: types.RECEIVE_TEAMS,
    teams
});

export const receiveTestimonials = testimonials => ({
    type: types.RECEIVE_TESTIMONIALS,
    testimonials
})
// refresh local storage

export const refreshUnSafe = (current) => ({
    type: types.REFRESH_STORE,
    current
})

export const refreshStore = (current) => dispatch => {
    dispatch(refreshUnSafe(current));
}

// get all products
export const getAllProducts = () => dispatch => {
    api.getProductsById().then(products => {
        dispatch(receiveProducts(products));
        return products;
    })
}

// get all products
export const getAllCategories = () => dispatch => {
    api.getAllSubCategories().then(categories => {
        dispatch(receiveCategories(categories));
        return categories;
    })
}

// get teams

export const getTeams = () => dispatch => {
    api.getTeamMembers().then(teams => {
        dispatch(receiveTeams(teams));
        return teams;
    })
}

// get testimonials

export const getTestimonials = () => dispatch => {
    api.getTestimonials().then(testimonials => {
        dispatch(receiveTestimonials(testimonials));
        return testimonials;
    })
}


/*********** Modal related Action **********/
// display quickview 
export const showQuickViewModal = productId => ({
    type: types.SHOW_QUICKVIEW,
    productId
})

// close quickview modal
export const closeQuickViewModal = () => ({
    type: types.CLOSE_QUICKVIEW
})

// Show Video & Login modal
export const showModal = (modal) => ({
    type: types.SHOW_MODAL,
    modal: modal
});

// close Video & Login modal
export const closeModal = (modal) => ({
    type: types.CLOSE_MODAL,
    modal: modal
});

/************ Cart Action **************/
// add item to cart
export const addToCart = (product, qty, total) => (dispatch) => {
    dispatch(addToCartUnsafe(product, qty));
}

// add item to cart : typical action
export const addToCartUnsafe = (product, qty) => ({
    type: types.ADD_TO_CART,
    product,
    qty
});


// remove item from cart
export const removeFromCart = productId => (dispatch) => {
    toast.error("Item removed from Cart");

    dispatch({
        type: types.REMOVE_FROM_CART,
        productId
    })
};

// change item's qty
export const changeQty = (productId, qty) => ({
    type: types.CHANGE_QTY,
    productId,
    qty
});

// empty cart

export const emptyCart = () => ({
    type: types.EMPTY_CART
})

// change shipping method
export const changeShipping = (shipping) => ({
    type: types.CHANGE_SHIPPING,
    shipping
})

// Delivery Details

export const getDeliveryDetails = (agent) => ({
    type: types.DELIVERY_DETAILS,
    agent
})

/************** Filter Action ***********/

// set search
export const search = (search) => (dispatch) => {
    dispatch({
        type: types.SEARCH_FILTER,
        search
    })
}

// set order to sort
export const filterSort = (sortBy) => (dispatch) => {
    dispatch({
        type: types.SORT_BY,
        sortBy
    })
}
// add/remove category to get suitable products
export const toggleCategoryFilter = (category) => (dispatch) => {
    dispatch({
        type: types.CATEGORY_FILTER,
        category
    })
}

// add/remove category to get suitable products
export const toggleSubCategoryFilter = (category) => (dispatch) => {
    dispatch({
        type: types.SUBCATEGORY_FILTER,
        category
    })
}

// reset filter with intialstate
export const resetFilter = () => (dispatch) => {
    dispatch({
        type: types.RESET_FILTER
    })
}

//wallet
export const walletUpdate = (amount) => (dispatch) => {
    dispatch({
        type: types.WALLETUPDATE,
        amount
    })
}
