import { ADD_TO_CART, REMOVE_FROM_CART, CHANGE_QTY, CHANGE_SHIPPING, REFRESH_STORE, DELIVERY_DETAILS, EMPTY_CART } from "../constants/action-types";
import { findIndex } from "../utils";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
    cart: [],
    shipping: "free",
    agent: []
}

function cartReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_TO_CART:
            const productId = action.product.id;
            const itemId = action.product.item && action.product.item.id;

            if (findIndex(state.cart, product => product.id === productId && product.item.id === itemId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === productId && product.item.id === itemId) {
                        cartAcc.push({ ...product, qty: parseInt(product.qty) + parseInt(action.qty), sum: (parseFloat(product.item.scost)) * (parseInt(product.qty) + parseInt(action.qty)) }) // Increment qty
                    } else {
                        cartAcc.push(product)
                    }
                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return {
                ...state,
                cart: [
                    ...state.cart,
                    {
                        ...action.product,
                        qty: action.qty,
                        sum: (parseInt(action.product.item) ? parseFloat(action.product.item.scost) : parseFloat(action.product.item.scost)) * action.qty
                    }
                ]
            }
        case DELIVERY_DETAILS:
            return {
                ...state,
                agent: action.agent
            }
        case REMOVE_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter(item => item.item.id !== action.productId)
            };

        case CHANGE_QTY:
            const cart = state.cart.reduce((cartAcc, product) => {
                if (product.item.id === action.productId) {
                    cartAcc.push({ ...product, qty: parseInt(action.qty), sum: parseInt(product.item.scost) * parseInt(action.qty) }) // Increment qty
                } else {
                    cartAcc.push(product)
                }
                return cartAcc;
            }, [])

            return { ...state, cart };

        case REFRESH_STORE:
            return { ...state, cart: [], shipping: "free", agent: [] };

        case EMPTY_CART:
            return { ...state, cart: [], shipping: "free", agent: [] };

        case CHANGE_SHIPPING:
            return { ...state, shipping: action.shipping };

        default:
            return state;
    }
}

const persistConfig = {
    keyPrefix: "milagrow-",
    key: "cartlist",
    storage
}

export default persistReducer(persistConfig, cartReducer);