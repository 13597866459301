import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-app-polyfill/ie11';
import ReactGA from 'react-ga';

import { PersistGate } from 'redux-persist/integration/react';

// import store
import store, { persistor } from './store';

// import action
import { getAllCategories, getAllProducts, refreshStore, getTestimonials, getTeams } from './actions';

// import routes
import AppRoute from './routes';
import RouteChangeTracker from './components/milagrow/routeChangeTracker';
// import Utils
import { initFunctions } from './utils';

import LoadingOverlay from './components/features/loading-overlay';
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});
export function Root() {
    const TRACKING_ID = "UA-210863780-1"; // YOUR_OWN_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);
    initFunctions();
    store.dispatch(getAllProducts());
    store.dispatch(getAllCategories());
    // store.dispatch(getTeams());
    store.dispatch(getTestimonials());

    useEffect(() => {
        if (store.getState().modal.current !== 11) {
            store.dispatch(refreshStore(11));
        }
    }, [])
   
    return (
        <Provider store={store} >
            <PersistGate persistor={persistor} loading={<LoadingOverlay />}>
                <BrowserRouter basename={`/`} >
                <RouteChangeTracker />
                    <AppRoute />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));
