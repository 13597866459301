import {
  RECEIVE_PRODUCTS,
  SHOW_QUICKVIEW,
  CLOSE_QUICKVIEW,
  RECEIVE_CATEGORIES,
  RECEIVE_SUBCATEGORIES,
  REFRESH_STORE,
  RECEIVE_TESTIMONIALS,
  RECEIVE_TEAMS
} from "../constants/action-types";
import { findIndex } from "../utils";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  products: [],
  productCategories: [],
  productDetail: [],
  quickView: false,
  subCategories: [],
  teams: [],
  testimonials: []
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };

    case RECEIVE_CATEGORIES:
      return {
        ...state,
        productCategories: action.categories,
      };

    case RECEIVE_SUBCATEGORIES:
      let category = Object.assign([], state.subCategories);
      let categories = action.categories;
      if (categories && categories.length > 0) {
        for (let i = 0; i < categories.length; i++) {
          let subindex = findIndex(category, item => item.sname === categories[i].sname);
          if (-1 === subindex) {
            category.push(categories[i]);
          } else {
            category.splice(subindex, 1);
          }
        }
      }
      else if (!Array.isArray(categories) && !categories) {
        category = [];
      }

      return {
        ...state,
        subCategories: category,
      };

    case RECEIVE_TEAMS:
      return {
        ...state,
        teams: action.teams
      }

    case RECEIVE_TESTIMONIALS:
      return {
        ...state,
        testimonials: action.testimonials
      }
    case SHOW_QUICKVIEW:
      let index = findIndex(
        state.products,
        (product) => product.id === action.productId
      );

      if (-1 !== index) {
        const item = state.products[index];
        console.log(item);
        return {
          ...state,
          quickView: true,
          productDetail: item,
        };
      }
      break;

    case CLOSE_QUICKVIEW:
      return { ...state, quickView: false };

    case REFRESH_STORE:
      return {
        ...state,
        subCategories: []
      }
    default:
      return state;
  }
};
const persistConfig = {
  keyPrefix: "milagrow-",
  key: "products",
  storage,
};

export default persistReducer(persistConfig, productReducer);
