import {
    RECEIVE_ORDERS,
    SET_ORDER_DETAILS,
    RESET_ORDERS,
    SET_ORDERID
} from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const initialState = {
    orders:[],
    orderDetail:[],
    orderid:''
};

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_ORDERS:
            return {
                ...state,
                orders: action.orders
            };
        case SET_ORDER_DETAILS:
            return {
                ...state,
                orderDetail:action.order
            }
        case RESET_ORDERS:
            return{
                ...state,
                orders:[],
                orderDetail:[]
            }
        case SET_ORDERID:
            return{
                ...state,
                orderid:action.orderid
            }
        default:
            return state;
    }
};

const persistConfig = {
    keyPrefix: "milagrow-",
    key: "orders",
    storage,
};

export default persistReducer(persistConfig, ordersReducer);
