import { combineReducers } from 'redux';

// Import custom components
import productReducer from './products';
import cartReducer from './cart';
import filterReducer from './filter';
import modalReducer from './modal';
import userReducer from './user';
import addressesReducer from './addresses';
import ordersReducer from './orders';

const rootReducer = combineReducers({
    data: productReducer,
    cartlist: cartReducer,
    filters: filterReducer,
    modal: modalReducer,
    user: userReducer,
    addresses: addressesReducer,
    userOrders: ordersReducer
});


export default rootReducer;
