import {
    LOGIN, LOGOUT, CUSTOMER, WALLETUPDATE
} from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const initialState = {
    userid: 0,
    fName: "",
    lName: "",
    userName: "",
    mobileNumber: 0,
    userEmail: "",
    roleid: "",
    tokenId: "",
    sessionId: "",
    ldateTime: "",
    walletBalance: 0,
    loggedIn: false,
    customer: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                userid: action.user.userid,
                fName: action.user.fName,
                lName: action.user.lName,
                userName: action.user.userName,
                mobileNumber: action.user.mobileNumber,
                userEmail: action.user.userEmail,
                roleid: action.user.roleid,
                tokenId: action.user.tokenId,
                sessionId: action.user.sessionId,
                ldateTime: action.user.ldateTime,
                loggedIn: action.user.loggedIn,
                walletBalance: action.user.walletBalance
            };
        case LOGOUT:
            return {
                ...initialState
            }
        case CUSTOMER:
            return {
                ...state,
                customer: action.customer
            }
        case WALLETUPDATE:
            return {
                ...state,
               walletBalance: action.amount
            }
        default:
            return state;
    }
};

const persistConfig = {
    keyPrefix: "milagrow-",
    key: "user",
    storage,
};

export default persistReducer(persistConfig, userReducer);
