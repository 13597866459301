import {
    GET_ADDRESSES, SET_DEFAULT_ADDRESS, RESET_ADDRESSES, CHANGE_PAYMENT_TYPE, SET_SELECTED_ADDRESS
} from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const initialState = {
    addresses:[],
    defaultAddress:[],
    paymentType:"",
    selectedAddress:[]
};

const addressesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADDRESSES:
            return {
                ...state,
                addresses: action.addresses
            };
        case SET_DEFAULT_ADDRESS:
            return{
                ...state,
                defaultAddress:action.address
            }
        case SET_SELECTED_ADDRESS:
            return {
                ...state,
                selectedAddress:action.address
            }
        case RESET_ADDRESSES:
            return {
                addresses:[],
                defaultAddress:[],
                selectedAddress:[],
                paymentType:""
            }
        case CHANGE_PAYMENT_TYPE:
            return{
                ...state,
                paymentType:action.paymentType
            }
        default:
            return state;
    }
};

const persistConfig = {
    keyPrefix: "milagrow-",
    key: "addresses",
    storage,
};

export default persistReducer(persistConfig, addressesReducer);
