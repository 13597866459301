import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import LoadingOverlay from '../components/features/loading-overlay';

import { scrollTop } from '../utils';

const ProductPages = React.lazy(() => import('./products-route.js'));
const HomePage = React.lazy(() => import('./home-route.js'));
const CategoriesPage = React.lazy( () => import( './category-route.js') );

export default function AppRoot() {
    useEffect(() => {
        scrollTop();
    }, [])

    return (
        <React.Suspense fallback={<LoadingOverlay />}>
            <Switch>
                <Route path={`${process.env.PUBLIC_URL}/categories`} component={CategoriesPage} />
                <Route path={`${process.env.PUBLIC_URL}/product`} component={ProductPages} />
                <Route path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
            </Switch>
        </React.Suspense>
    )
}
