// Get Products
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const RECEIVE_SUBCATEGORIES = 'RECEIVE_SUBCATEGORIES';
export const RECEIVE_TEAMS = 'RECEIVE_TEAMS';
export const RECEIVE_TESTIMONIALS = 'RECEIVE_TESTIMONIALS';

//Orders
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const RESET_ORDERS = 'RESET_ORDERS';
export const SET_ORDERID = 'SET_ORDERID';

// Refresh Store
export const REFRESH_STORE = 'REFRESH_STORE';

//Quick     
export const SHOW_QUICKVIEW = 'SHOW_QUICKVIEW';
export const CLOSE_QUICKVIEW = 'CLOSE_QUICKVIEW';

// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CHANGE_QTY = 'CHANGE_QTY';
export const CHANGE_SHIPPING = 'CHANGE_SHIPPING';
export const DELIVERY_DETAILS = 'DELIVERY_DETAILS';
export const EMPTY_CART = 'EMPTY_CART';


//Product Filters
export const SORT_BY = 'SORT_BY';
export const CATEGORY_FILTER = 'CATEGORY_FILTER';
export const SUBCATEGORY_FILTER = 'SUBCATEGORY_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const SEARCH_FILTER = 'SEARCH_FILTER';



// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const REMOVE_NEWSLETTER = 'REMOVE_NESLETTER';

//Login
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CUSTOMER = 'CUSTOMER';

//Wallet
export const WALLETUPDATE = 'WALLETUPDATE'

//Addresses
export const GET_ADDRESSES = 'GET_ADDRESSES';
export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS';
export const RESET_ADDRESSES = 'RESET_ADDRESSES';
export const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS';

export const CHANGE_PAYMENT_TYPE = 'CHANGE_PAYMENT_TYPE';
