import * as types from '../constants/action-types'
import { findIndex } from "../utils";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
    value: { min: 0, max: 10000 },
    sortBy: "popularity",
    category: [],
    subcategory: [],
    size: [],
    color: [],
    brand: [],
    rating: [],
    search: ''
}

function filterReducer(state = initialState, action) {
    switch (action.type) {
        case types.SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy
            };

        case types.SEARCH_FILTER:

            return {
                ...state,
                search: action.search
            };

        case types.CATEGORY_FILTER:
            let category = Object.assign([], state.category);
            let index = findIndex(category, item => item === action.category);
            if (-1 === index) {
                category.push(action.category);
            } else {
                category.splice(index, 1);
            }
            return {
                ...state,
                category: category
            };

        case types.SUBCATEGORY_FILTER:
            let subcategory = Object.assign([], state.subcategory);

            if (Array.isArray(action.category)) {
                for (let i = 0; i < action.category.length; i++) {
                    let subindex = findIndex(subcategory, item => item === action.category[i].sname);
                    if (-1 !== subindex)
                        subcategory.splice(subindex, 1);
                }
            }
            else {
                let subindex = findIndex(subcategory, item => item === action.category);
                if (-1 === subindex) {
                    subcategory.push(action.category);
                } else {
                    subcategory.splice(subindex, 1);
                }
            }

            return {
                ...state,
                subcategory: subcategory
            };

        case types.RESET_FILTER:
            return {
                value: { min: 0, max: 10000 },
                sortBy: "popularity",
                category: [],
                subcategory: [],
                size: [],
                color: [],
                brand: [],
                rating: [],
                search: ''
            };

        default:
            return state;
    }
}

const persistConfig = {
    keyPrefix: 'milagrow-',
    key: "filter",
    storage
}

export default persistReducer(persistConfig, filterReducer);
